// index.tsx ou index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import './ress.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth';
import { MainProvider } from './contexts/Main';
import * as Sentry from '@sentry/react';
import FallbackError from './components/common/FallbackError';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@/theme/theme';
import './i18n';

Sentry.init({
    dsn: 'https://e4e70de91705422db230ce673014dd38@o4504243829866496.ingest.sentry.io/4504243830784000',
    tracesSampleRate: 1.0,
});

ReactPixel.init('1632320730601227');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

ReactGA.initialize('G-D1Q6TVYLS2');

root.render(
    <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<FallbackError />}>
            <BrowserRouter>
                <AuthProvider>
                    <MainProvider>
                        <HelmetProvider>
                            <App />
                        </HelmetProvider>
                    </MainProvider>
                </AuthProvider>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    </ThemeProvider>
);

const sendAnalytics = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
    });
};

// Mesure des performances de l'application
reportWebVitals(sendAnalytics);
