import axios, { AxiosError } from 'axios';
import { User as FirebaseUser } from 'firebase/auth';
import { API_BASE_URL } from '../constants';
import * as Sentry from '@sentry/react';

class ApiClient {
    user: FirebaseUser | null;
    axiosInstance;
    superUserKey: string | null;

    constructor() {
        this.user = null;
        this.superUserKey = null;
        this.axiosInstance = axios.create({
            baseURL: API_BASE_URL,
            timeout: 20000,
            headers: { 'Content-Type': 'application/json' },
        });
        this.handle401 = this.handle401.bind(this);

        this.axiosInstance.interceptors.request.use((config) => {
            config.params = {
                ...config.params,
                ...(this.superUserKey && { superUserKey: this.superUserKey }),
            };
            return config;
        });

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            this.handle401
        );
    }

    async init(firebaseUser: FirebaseUser) {
        const accessToken = await firebaseUser.getIdToken();
        this.setAccessToken(accessToken);
        this.user = firebaseUser;
    }

    setSuperUserKey(key: string | null) {
        this.superUserKey = key;
    }

    setAccessToken(token?: string) {
        this.axiosInstance.defaults.headers.common['Authorization'] =
            token || '';
    }

    signOut() {
        this.user = null;
        this.superUserKey = null;
        this.axiosInstance.defaults.headers.common['Authorization'] = '';
    }

    // refresh access token in case of 401 api error
    async handle401(e: AxiosError) {
        //@ts-expect-error
        if (e.response?.status !== 401 || e.config._is401Retry) {
            Sentry.captureException(e);
            return Promise.reject(e);
        }

        if (e.response?.status === 401) {
            try {
                const newToken = (await this.user?.getIdToken(true)) || '';
                this.axiosInstance.defaults.headers.common['Authorization'] =
                    newToken;

                e.config.headers = {
                    ...e.config.headers,
                    Authorization: newToken,
                };

                //@ts-expect-error
                e.config._is401Retry = true;
                return this.axiosInstance(e.config);
            } catch (_e) {
                return Promise.reject(_e);
            }
        }
    }
}

const ApiClientInstance = new ApiClient();

export const api = ApiClientInstance.axiosInstance;

export default ApiClientInstance;
