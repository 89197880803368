import React, { useEffect, useRef, useState } from 'react';
import {
    createFirebaseUser,
    firebaseAuth,
    loginFirebaseUser,
    sendFbPasswordResetEmail,
    signInWithGoogle as signInWithGooglePopUp,
} from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import * as api from '../api/services';
import { User, Role, UserOrigin, Language, SubscriptionFormula } from '../types/types';
import Api from '@/api/client';
import { getRealTimeCapital } from '../api/services';

export interface AuthContextValue {
    user: User | null;
    signInWithGoogle: () => Promise<void>;
    signOut: () => void;
    hasLoadedUser: boolean;
    signUpWithGoogle: (role: Role) => Promise<void>;
    setUser: (user: User) => void;
    syncUser: () => Promise<void>;
    signUpWithEmailPass: (user: SignUpWithEmailParams) => Promise<void>;
    signInWithEmailPass: (email: string, password: string) => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    setFromMentorId: (mentorId: string | null) => void;
    fromMentorId: string | null;
    setMayGoToExplorer: (mayGoToExplorer: boolean) => void;
    mayGoToExplorer: boolean;
}

export interface SignUpWithEmailParams {
    email: string;
    password: string;
    role: Role;
    pseudo: string;
    firstName: string;
    lastName: string;
    language: Language;
    mentorPrice?: string;
    subscriptionFormula?: SubscriptionFormula;
}

const defaultValue = {
    user: null,
    hasLoadedUser: false,
};

// @ts-expect-error
const AuthContext = React.createContext<AuthContextValue>(defaultValue);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const [hasLoadedUser, setHasLoadedUser] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [fromMentorId, setFromMentorId] = useState<string | null>(null);
    const [mayGoToExplorer, setMayGoToExplorer] = useState(false);
    const hasCheckedUser = useRef(false);

    // used to detect user at initial page load (or refresh)
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (firebaseUser) => {
                if (hasCheckedUser.current) return;
                hasCheckedUser.current = true;

                if (firebaseUser) {
                    try {
                        await Api.init(firebaseUser);
                        const { data: user } = await api.getUser();
                        Api.setSuperUserKey(user.superUserKey!);
                        setUser(user);
                    } catch (error) {
                        signOut();
                    }
                }
                setHasLoadedUser(true);
            }
        );
        return unsubscribe;
    }, []);

    useEffect(() => {
        const updateTotalCapital = async () => {
            try {
                const { data } = await getRealTimeCapital();
                setUser((prev) => ({ ...prev!, totalCapital: data.capital }));
            } catch (error) {}
        };

        if (user?.id && user?.role === Role.TRADER) {
            updateTotalCapital();
            const interval = setInterval(updateTotalCapital, 5 * 60 * 1000);
            return () => clearInterval(interval);
        }
    }, [user?.id, user?.role]);

    const signUpWithGoogle = async (role: Role) => {
        try {
            const { user: firebaseUser } = await signInWithGooglePopUp();
            await Api.init(firebaseUser);
            const { data: user } = await api.createUser({
                role,
                origin: UserOrigin.GOOGLE,
            });
            setUser(user);
            // navigate('/', { replace: true });
        } catch (error) {
            await signOut();
            throw error;
        }
    };

    const signInWithGoogle = async () => {
        try {
            const { user: firebaseUser } = await signInWithGooglePopUp();
            await Api.init(firebaseUser);
            const { data: user } = await api.getUser();
            setUser(user);
            // navigate('/', { replace: true });
        } catch (error) {
            signOut();
            throw error;
            //  handleError
        }
    };

    const signUpWithEmailPass = async ({
        firstName,
        lastName,
        pseudo,
        role,
        email,
        password,
        language,
        mentorPrice,
        subscriptionFormula,
    }: SignUpWithEmailParams) => {
        try {
            const { user: firebaseUser } = await createFirebaseUser(
                email,
                password
            );
            await Api.init(firebaseUser);
            const { data: user } = await api.createUser({
                role,
                origin: UserOrigin.EMAIL_PASSWORD,
                firstName,
                lastName,
                pseudo,
                language,
                mentorPrice,
                subscriptionFormula,
            });
            setUser(user);
            if (user.role === Role.MENTEE) setMayGoToExplorer(true);
        } catch (error) {
            await signOut();
            throw error;
        }
    };

    const signInWithEmailPass = async (email: string, password: string) => {
        try {
            const { user: firebaseUser } = await loginFirebaseUser(
                email,
                password
            );
            await Api.init(firebaseUser);
            const { data: user } = await api.getUser();
            Api.setSuperUserKey(user.superUserKey!);
            setUser(user);
            if (user.role === Role.MENTEE) setMayGoToExplorer(true);
            // if (user.role === Role.TRADER) navigate('/profile ', { replace: true });
        } catch (error) {
            signOut();
            throw error;
            //  handleError
        }
    };

    const signOut = async () => {
        // console.log('SIGNOUT');

        await firebaseAuth.signOut();
        Api.signOut();
        setUser(null);
    };

    const resetPassword = (email: string) => {
        return sendFbPasswordResetEmail(email);
    };

    const syncUser = async () => {
        const { data: newUserData } = await api.getUser();
        setUser((prevUserData) => ({
            totalCapital: prevUserData?.totalCapital,
            ...newUserData,
        }));
    };

    const value = {
        user,
        signInWithGoogle,
        signOut,
        hasLoadedUser,
        signUpWithGoogle,
        signUpWithEmailPass,
        signInWithEmailPass,
        resetPassword,
        setUser,
        syncUser,
        setFromMentorId,
        fromMentorId,
        setMayGoToExplorer,
        mayGoToExplorer,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
