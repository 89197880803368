import React from 'react';
import './App.css';

import { useAuth } from './contexts/Auth';
// import { Role } from './types/types';

const AuthRoutes = React.lazy(() => import('./routes/Auth'));
const TraderRoutes = React.lazy(() => import('./routes/Trader'));
// const MenteeRoutes = React.lazy(() => import('./routes/Mentee'));

function App() {
    const { hasLoadedUser, user } = useAuth();

    if (!hasLoadedUser) return null;

    if (!user) return <AuthRoutes />;

    return <TraderRoutes />;

    // if (user.role === Role.TRADER) return <TraderRoutes />;

    // if (user.role === Role.MENTEE) return <MenteeRoutes />;

    // return null;
}

export default App;
