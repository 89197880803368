// src/theme/theme.ts
import { createTheme, ThemeOptions } from '@mui/material/styles';
import {grey} from "@mui/material/colors";

import '@mui/material/Button';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlinedCustom: true;
    }
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;        // Retain default 'xs' breakpoint
        sm: true;        // Retain default 'sm' breakpoint
        md: true;        // Retain default 'md' breakpoint
        lg: true;        // Retain default 'lg' breakpoint
        xl: true;        // Retain default 'xl' breakpoint
        tinyMobile: true;
        mobile: true;
        tablet: true;
        desktop: true;
        // Remove default breakpoints if not used
        // xs: false;
        // sm: false;
        // md: false;
        // lg: false;
        // xl: false;
    }
}

const theme = createTheme({
    spacing: 8,
    palette: {
        primary: {
            main: '#02779d',  // Bleu principal de l'image
        },
        secondary: {
            main: '#f8f9fa',  // Gris clair de fond
        },
        text: {
            primary: '#333',  // Noir/gris pour le texte principal
            secondary: grey[600],  // Texte secondaire (liens, etc.)
        },
        background: {
            root: '#f3f7f9',  // Fond blanc
            default: '#f3f7f9',  // Fond blanc
            paper: '#ffffff',
            grid: '#ffffff', // Fond des cartes ou sections
        },
    },
    typography: {
        fontFamily: '"Inter Tight", "Roboto", "Arial", sans-serif', // Police principale
        h1: {
            fontWeight: 700,
            fontSize: '2.5rem',
            lineHeight: 1.2,
        },
        h2: {
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: 1.3,
        },
        h3: {
            fontWeight: 550,
            fontSize: '1.5rem',
            lineHeight: 1.2,
        },
        h4: {
            fontWeight: 450,
            fontSize: '1.4rem',
            lineHeight: 1.1,
        },
        h5: {
            fontWeight: 400,
            fontSize: '1.3rem',
            lineHeight: 1.1,
        },
        h6:{
            fontWeight: 200,
            fontSize: '1.1rem',
            lineHeight: 1.1,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.6,
        },
        body2: {
            fontSize: '0.7rem',
            lineHeight: 1.3,
        },
        button: {
            fontWeight: 600,
            textTransform: 'none',
            borderRadius: 10,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            tinyMobile: 355,
            sm: 600,
            mobile: 768,
            md: 900,
            tablet: 1024,
            desktop: 1280,
            lg: 1200,
            xl: 1536,
        },
    },
    components : {
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '#0793B2', // Couleur du texte des Chip par défaut
                    backgroundColor: '#fff', // Couleur de fond des Chip par défaut
                    borderRadius: '4px', // Coins arrondis
                    fontSize: '0.75rem', // Taille de la police
                },
                colorPrimary: {
                    color: '#0793B2', // Couleur du texte des Chip outlined primaire
                    borderColor: '#0793B2', // Couleur de la bordure
                },
                colorSecondary: {
                    color: '#33CFFF', // Couleur du texte des Chip outlined secondaire
                    borderColor: '#33CFFF', // Couleur de la bordure
                },
                colorInfo: {
                    color: '#40C4AA', // Couleur du texte des Chip outlined info
                    borderColor: '#40C4AA', // Couleur de la bordure
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#0793B2',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#008fcc',
                        boxShadow: 'none',
                    },
                    color: '#fff'
                },
            },
            variants: [
                {
                    props: { variant: 'outlinedCustom' },
                    style: {
                        border: '1px solid rgba(0, 0, 0, 0.23)', // Bordure grise légère
                        borderRadius: '8px', // Bordure arrondie
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        padding: '6px 16px', // Espacement intérieur
                        color: 'rgba(0, 0, 0, 0.87)', // Texte sombre
                        textTransform: 'none', // Garde le texte tel quel (pas en majuscules)
                        fontWeight: 500,
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Légère couleur de fond au survol
                            borderColor: 'rgba(0, 0, 0, 0.23)', // Couleur de la bordure au survol
                        },
                    },
                },
            ],
        },
    }
    // ...other theme customizations
} as ThemeOptions);

export default theme;