import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(HttpApi)  // Utilise HTTP backend pour charger les fichiers de traduction locaux
    .use(LanguageDetector)  // Détecte automatiquement la langue de l'utilisateur
    .use(initReactI18next)  // Liaison à React
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',  // Chemin des fichiers de traduction locaux
        },
        fallbackLng: 'en',  // Langue de secours si une traduction est manquante
        lng: 'en',  // Langue par défaut
        ns: ['common', 'auth', 'layout', 'leftmenu', 'profilemenu', 'notifications', 'explorer', 'trader', 'myMentors'],  // Namespaces que tu veux utiliser
        defaultNS: 'common',  // Namespace par défaut
        interpolation: {
            escapeValue: false,  // Désactive l'échappement des valeurs (pas nécessaire avec React)
        },
        debug: true,  // Activer pour voir les logs dans la console (optionnel)
    });

export default i18n;
