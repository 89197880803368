import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyBvFXmHW3eDNhHTRZHyoU7vYsGuUmm3Seg',
    authDomain: 'slice-359019.firebaseapp.com',
    projectId: 'slice-359019',
    storageBucket: 'slice-359019.appspot.com',
    messagingSenderId: '648470582600',
    appId: '1:648470582600:web:4bc4ebee9ac0ccbb54cf7d',
    measurementId: 'G-ETMY7MEX0X',
};

export const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');
googleProvider.setCustomParameters({ prompt: 'select_account' });
// googleProvider.setDefaultLanguage('fr');
export const signInWithGoogle = () =>
    signInWithPopup(firebaseAuth, googleProvider);

export const createFirebaseUser = (email: string, password: string) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
};

export const loginFirebaseUser = (email: string, password: string) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const sendFbPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(firebaseAuth, email);
};
