import React, { useState } from 'react';

export interface MainContextValue {
    isProfileMenuVisible: boolean;
    setIsProfileMenuVisible: (state: boolean) => void;
}

// @ts-expect-error
const MainContext = React.createContext<MainContextValue>();

interface ProviderProps {
    children: React.ReactNode;
}

export const MainProvider = ({ children }: ProviderProps) => {
    const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);

    const value = {
        isProfileMenuVisible,
        setIsProfileMenuVisible,
    };

    return (
        <MainContext.Provider value={value}>{children}</MainContext.Provider>
    );
};

export const useMainContext = () => {
    const context = React.useContext(MainContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
